import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import GirAnimada from "../component/animation/AnimationG";
import { AnimationSlider } from "../component/animation/AnimationSlider";
import ContactTwo from "./contact/ContactTwo";

// imp  ort ModalContact from "../component/common/ModalContact";

class ServiceDetails extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  componentDidUpdate() {}
  constructor() {
    super();
    this.state = {
      isOpen: false
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Videos en After Effect " pageDesc="Productora Multimedia en Panamá."/>
        {/* End Pagehelmet  */}
        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--1"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h1 className="title theme-gradient">Vídeos marketing</h1>
                  <p>
                  En Social Media Panamá desarrollamos tus ideas y las llevamos al siguiente nivel. <br></br>Somos especialistas en crear obras únicas y originales que ayudan a destacar tu producto o servicio. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}
        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="page-title mb--50">
                    <h2 className="title">
                    Vídeos publicitarios
                    </h2>
                    <p>
                    Hacemos animaciones personalizadas para tus videos. Nuestra productora ayuda a las personas a trasmitir su mensaje de una forma creativa y profesional. 
                    </p>
                  </div>
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                      <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src="/assets/images/service/video-after-effect.png"
                            alt="Productora de Video de After Effect"
                          />
                          <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="8zC6YIgNuAU"
                            onClose={() => this.setState({ isOpen: false })}
                          />
                          <button
                            className="video-popup"
                            onClick={this.openModal}
                          >
                            <span className="play-icon"></span>
                          </button>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h3 className="title mt-1">Videos educativos</h3>
                          <p>
                          ¿Qué se necesita para hacer un vídeo?. 
                          <br></br>
                          Tener un idea de qué quieres explicar. <br></br>
                          Te ayudamos en convertir tu idea en un guión, de ahí un storyboard, una animación y finalmente el vídeo con toda la animación y el Sound Design. 
                          </p>                          
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                        <h3 className="title mt-1">Presentaciones mejoradas</h3>
                          <p>
                         Si tienes un video en donde explicas un PPT,
                          <br></br>
                          Nosotros lo convertimos una experiencia mejorada.
                          </p>    
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src="/assets/images/service/video-after-effect-panama.png"
                            alt="Video de After Effect"
                          />
                          <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="E3v2Gm564mk"
                            onClose={() => this.setState({ isOpen: false })}
                          />
                          <button
                            className="video-popup"
                            onClick={this.openModal}
                          >
                            <span className="play-icon"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                    
                    {/* Start Single Area */}
                    {/* Start Contact Page Area  */}
                    <div className="rn-contact-page ptb--120 bg_color--1">
                      <ContactTwo />
                    </div>
                    {/* End Contact Page Area  */}
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* End Page Wrapper */}
      </React.Fragment>
    );
  }
}
export default ServiceDetails;
