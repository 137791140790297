import React from "react";

import "./estilosIcon.css";

const getViewBox = (name) => {
  switch (name) {
    case "WD":
      return "0 0 54 54";
    case "RS":
      return "0 0 54 54";

    case "SEO":
      return "0 0 54 54";
    case "WP":
      return "0 0 54 54";
    case "ADS":
      return "0 0 54 54";
    case "CONST":
      return "0 0 54 54";
    case "PHOTO":
      return "0 0 54 54";
    case "OJS":
      return "0 0 54 54";
    case "WIFI":
      return "0 0 54 54";
    case "PLAY":
      return "0 0 54 54";
    default:
      return "0 0 32 32";
  }
};

const getPath = (name, props) => {
  switch (name) {
    case "SEO":
      return (
        <React.Fragment>
          <g>
            <path
              d="M45,14.9h-3.1L25.3,0.5c-0.8-0.7-1.9-0.7-2.6,0L6.1,14.9H3c-1.7,0-3,1.3-3,3V45c0,1.7,1.3,3,3,3h42c1.7,0,3-1.3,3-3V17.9
		C48,16.2,46.7,14.9,45,14.9z M24,4.6l11.8,10.2H12.2L24,4.6z M44,44H4V18.9h40V44z"
            />
            <polygon points="8.7,30.3 10.3,30.3 10.3,24.4 12.3,24.4 12.3,23.1 6.6,23.1 6.6,24.4 8.7,24.4 	" />
            <path
              d="M16.7,30.3c1.1,0,2-0.4,2.7-1.1c0.7-0.7,1.1-1.6,1.1-2.7c0-1.1-0.4-1.9-1.1-2.7c-0.7-0.7-1.6-1.1-2.7-1.1
		c-1.1,0-2,0.4-2.7,1.1c-0.7,0.7-1.1,1.6-1.1,2.7c0,1.1,0.4,1.9,1.1,2.7C14.7,30,15.6,30.3,16.7,30.3z M15.2,25
		c0.4-0.5,0.9-0.7,1.5-0.7c0.6,0,1.1,0.2,1.5,0.7c0.4,0.5,0.6,1,0.6,1.6c0,0.6-0.2,1.2-0.6,1.6c-0.4,0.5-0.9,0.7-1.5,0.7
		c-0.6,0-1.1-0.2-1.5-0.7c-0.4-0.5-0.6-1-0.6-1.6C14.6,26,14.8,25.4,15.2,25z"
            />
            <path
              d="M23.4,28.3h1c1.1,0,1.9-0.2,2.4-0.6c0.5-0.4,0.8-1.1,0.8-2s-0.3-1.6-0.8-2c-0.5-0.4-1.3-0.6-2.4-0.6h-2.5v7.2h1.6V28.3z
		 M23.4,24.4h0.9c0.5,0,0.9,0.1,1.2,0.3c0.3,0.2,0.4,0.5,0.4,0.9c0,0.4-0.1,0.7-0.3,0.9c-0.2,0.2-0.6,0.3-1.1,0.3h-1.1V24.4z"
            />
            <polygon points="32.4,30.3 33.9,30.3 33.9,23.1 31,23.1 31,24.4 32.4,24.4 	" />
            <path
              d="M38.4,30.3c1,0,1.7-0.3,2.2-1c0.5-0.7,0.7-1.6,0.7-2.7c0-1.1-0.2-2-0.7-2.7c-0.5-0.7-1.2-1-2.2-1c-1,0-1.7,0.3-2.2,1
		c-0.5,0.7-0.7,1.6-0.7,2.7c0,1.1,0.2,2,0.7,2.7C36.6,30,37.4,30.3,38.4,30.3z M37.3,24.9c0.2-0.4,0.6-0.7,1.1-0.7
		c0.5,0,0.8,0.2,1.1,0.7c0.2,0.4,0.3,1,0.3,1.7c0,0.7-0.1,1.3-0.3,1.7c-0.2,0.4-0.6,0.7-1.1,0.7c-0.5,0-0.8-0.2-1.1-0.7
		c-0.2-0.4-0.3-1-0.3-1.7C37,25.9,37.1,25.3,37.3,24.9z"
            />
            <path
              d="M11.6,35.6l-0.8,0.1c-0.8,0.1-1.1,1.1-0.6,1.7l0.6,0.6c0.2,0.2,0.3,0.6,0.3,0.9L11,39.8c-0.1,0.8,0.7,1.4,1.5,1.1l0.8-0.4
		c0.3-0.2,0.6-0.2,0.9,0l0.8,0.4c0.7,0.4,1.6-0.2,1.5-1.1l-0.1-0.8c-0.1-0.3,0.1-0.7,0.3-0.9l0.6-0.6c0.6-0.6,0.3-1.6-0.6-1.7
		l-0.8-0.1c-0.3,0-0.6-0.3-0.8-0.5l-0.4-0.8c-0.4-0.7-1.4-0.7-1.8,0l-0.4,0.8C12.3,35.4,12,35.6,11.6,35.6z"
            />
            <path
              d="M20.6,37.4l0.6,0.6c0.2,0.2,0.3,0.6,0.3,0.9l-0.1,0.8c-0.1,0.8,0.7,1.4,1.5,1.1l0.8-0.4c0.3-0.2,0.6-0.2,0.9,0l0.8,0.4
		c0.7,0.4,1.6-0.2,1.5-1.1l-0.1-0.8c-0.1-0.3,0.1-0.7,0.3-0.9l0.6-0.6c0.6-0.6,0.3-1.6-0.6-1.7L26,35.6c-0.3,0-0.6-0.3-0.8-0.5
		l-0.4-0.8c-0.4-0.7-1.4-0.7-1.8,0l-0.4,0.8c-0.1,0.3-0.4,0.5-0.8,0.5l-0.8,0.1C20.3,35.9,20,36.9,20.6,37.4z"
            />
            <path
              d="M31.5,38c0.2,0.2,0.3,0.6,0.3,0.9l-0.1,0.8c-0.1,0.8,0.7,1.4,1.5,1.1l0.8-0.4c0.3-0.2,0.6-0.2,0.9,0l0.8,0.4
		c0.7,0.4,1.6-0.2,1.5-1.1l-0.1-0.8c-0.1-0.3,0.1-0.7,0.3-0.9l0.6-0.6c0.6-0.6,0.3-1.6-0.6-1.7l-0.8-0.1c-0.3,0-0.6-0.3-0.8-0.5
		l-0.4-0.8c-0.4-0.7-1.4-0.7-1.8,0L33,35.1c-0.1,0.3-0.4,0.5-0.8,0.5l-0.8,0.1c-0.8,0.1-1.1,1.1-0.6,1.7L31.5,38z"
            />
          </g>
        </React.Fragment>
      );
    case "RS":
      return (
        <React.Fragment>
          <g>
            <path
              d="M23.7,36.8c0-6.3,4.4-11.5,10.3-12.8c-1.1-0.8-2.2-1.5-3.4-2.1c-2.5,2.1-5.6,3.3-9.1,3.3s-6.6-1.3-9.1-3.3
		C5,25.5,0,33.4,0,42.6c9.3,5,18.9,6.5,28.8,4.6C25.7,44.8,23.7,41.1,23.7,36.8z"
            />
            <circle cx="21.4" cy="11" r="11" />
            <path
              d="M36.8,25.7c-6.2,0-11.2,5-11.2,11.2c0,6.2,5,11.2,11.2,11.2C43,48,48,43,48,36.8C48,30.7,43,25.7,36.8,25.7z M36.8,42.7
		c0,0-5.7-3.8-5.7-8.6c0-1.7,1.4-3.1,3.1-3.1c1.1,0,2,0.5,2.6,1.4c0.6-0.8,1.5-1.4,2.6-1.4c1.7,0,3.1,1.4,3.1,3.1
		C42.6,38.9,36.8,42.7,36.8,42.7z"
            />
          </g>
        </React.Fragment>
      );
    case "WD":
      return (
        <React.Fragment>
          <g>
            <polygon points="16.7,29.8 9.6,26.5 9.6,26.3 16.7,23.1 16.7,20.8 7.2,25.2 7.2,27.7 16.7,32 	" />
            <polygon points="29.5,18.1 27.5,18.1 18.8,36.7 20.8,36.7 	" />
            <polygon points="41,25.2 31.5,20.8 31.5,23.1 38.6,26.3 38.6,26.5 31.5,29.8 31.5,32 41,27.7 	" />
            <path
              d="M45,0H3C1.3,0,0,1.3,0,3v42c0,1.7,1.3,3,3,3h42c1.7,0,3-1.3,3-3V3C48,1.3,46.7,0,45,0z M36.3,3.2c1.1,0,2,0.9,2,2
          c0,1.1-0.9,2-2,2c-1.1,0-2-0.9-2-2C34.3,4.1,35.2,3.2,36.3,3.2z M3.2,5.2c0-1.1,0.9-2,2-2H26c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2H5.2
          C4.1,7.2,3.2,6.3,3.2,5.2z M43.7,43.3H4.3V11.2h39.3V43.3z M42.8,7.2c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2
          C44.8,6.3,43.9,7.2,42.8,7.2z"
            />
          </g>
        </React.Fragment>
      );
    case "WP":
      return (
        <React.Fragment>
          <g>
            <path
              d="M45,0H3C1.3,0,0,1.3,0,3v42c0,1.7,1.3,3,3,3h42c1.7,0,3-1.3,3-3V3C48,1.3,46.7,0,45,0z M36.3,2.6c1.1,0,2,0.9,2,2
		s-0.9,2-2,2c-1.1,0-2-0.9-2-2S35.2,2.6,36.3,2.6z M3.2,4.6c0-1.1,0.9-2,2-2H26c1.1,0,2,0.9,2,2s-0.9,2-2,2H5.2
		C4.1,6.6,3.2,5.7,3.2,4.6z M43.7,43.3H4.3V11.2h39.3V43.3z M42.8,6.6c-1.1,0-2-0.9-2-2s0.9-2,2-2c1.1,0,2,0.9,2,2
		S43.9,6.6,42.8,6.6z"
            />
            <polygon points="16.2,21.2 16.2,21.2 20,25.1 21.9,23.2 18,19.4 16.3,15.9 12.9,14.3 11,16.1 12.7,19.5 	" />
            <path d="M29.4,27.9l-4.8,4.8l6.7,6.7c0.9,0.9,2.3,0.9,3.2,0l1.6-1.6c0.9-0.9,0.9-2.3,0-3.2L29.4,27.9z" />
            <path
              d="M16.8,31c-1.5-0.4-3.2,0-4.3,1.2c-1.3,1.3-1.7,3.1-1.1,4.8l2.7-2.7l2.8,2.8l-2.7,2.7c1.6,0.6,3.5,0.2,4.8-1.1
		c1.2-1.2,1.6-2.8,1.2-4.3l11-11c1.5,0.4,3.2,0,4.3-1.2c1.3-1.3,1.7-3.1,1.1-4.8l-2.7,2.7l-2.8-2.8l2.7-2.7
		c-1.6-0.6-3.5-0.2-4.8,1.1c-1.2,1.2-1.6,2.8-1.2,4.3L16.8,31z"
            />
          </g>
        </React.Fragment>
      );
    case "ADS":
      return (
        <React.Fragment>
          <g>
            <path
              d="M45,7.2H24.6c-1,0-1.9-0.5-2.5-1.3l-3.2-4.6C18.3,0.5,17.4,0,16.4,0H3C1.3,0,0,1.3,0,3v42c0,1.6,1.3,3,3,3h42
		c1.7,0,3-1.4,3-3V10.2C48,8.5,46.7,7.2,45,7.2z M2.6,4.7c0-1.2,1-2.1,2.1-2.1c1.2,0,2.1,1,2.1,2.1c0,1.2-1,2.1-2.1,2.1
		C3.6,6.9,2.6,5.9,2.6,4.7z M44,44H4V11.2h40V44z"
            />
            <rect x="7.3" y="14.4" width="33.3" height="3" />
            <rect x="7.3" y="28.2" width="17.5" height="12.6" />
            <rect x="7.3" y="20.8" width="18" height="3" />
            <rect x="28.6" y="35" width="12.1" height="5.8" />
            <rect x="28.6" y="20.8" width="12.1" height="11.1" />
          </g>
        </React.Fragment>
      );
    case "CONST":
      return (
        <React.Fragment>
          <g>
            <path
              d="M31,23h-0.5l-4.6,11l-0.4-7.7l0.8-3.3h-4.5l0.8,3.3L22.1,34l-4.6-11H17C9.7,23,3.7,29,3.7,36.3V45c0,1.7,1.3,3,3,3h34.6
		c1.7,0,3-1.3,3-3v-8.7C44.3,29,38.3,23,31,23z"
            />
            <circle cx="24" cy="9.9" r="9.9" />
          </g>
        </React.Fragment>
      );
    case "PHOTO":
      return (
        <React.Fragment>
          <g>
            <path
              d="M28.6,18c-4.6,0-8.4,3.8-8.4,8.4s3.8,8.4,8.4,8.4c4.6,0,8.4-3.8,8.4-8.4S33.3,18,28.6,18z M25.8,24.4
		c-0.9,1-2.1,1.4-2.6,0.9c-0.6-0.5-0.4-1.7,0.5-2.7c0.9-1,2-1.4,2.6-0.9C26.9,22.2,26.6,23.4,25.8,24.4z"
            />
            <path
              d="M44.9,10.7h-5.7L38,7.9C37.5,6.8,36.4,6,35.1,6H24.2c-1.3,0-2.4,0.7-2.9,1.9l-1.2,2.8h-7.3v-1c0-0.7-0.6-1.3-1.3-1.3H7.9
		C7.2,8.5,6.7,9,6.7,9.7v1H3.1c-1.7,0-3.1,1.4-3.1,3.1v25C0,40.6,1.4,42,3.1,42h41.8c1.7,0,3.1-1.4,3.1-3.1v-25
		C48,12.1,46.6,10.7,44.9,10.7z M11,18.8H7.1c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6H11c0.9,0,1.6,0.7,1.6,1.6
		C12.5,18.1,11.9,18.8,11,18.8z M28.6,37.8c-6.3,0-11.5-5.2-11.5-11.5c0-6.3,5.2-11.5,11.5-11.5S40.2,20,40.2,26.3
		C40.2,32.7,35,37.8,28.6,37.8z"
            />
          </g>
        </React.Fragment>
      );
    case "OJS":
      return (
        <React.Fragment>
          <g>
            <path d="M35.8,30.7c-2.8,0-5,2.2-5,5c0,2.8,2.2,5,5,5c2.8,0,5-2.2,5-5C40.8,33,38.5,30.7,35.8,30.7z" />
            <path
              d="M48,37.6v-3.7l-2.7-0.5c-0.2-1-0.6-2-1.2-2.8l1.6-2.2l-2.6-2.6l-2.2,1.6c-0.9-0.5-1.8-0.9-2.8-1.2l-0.5-2.7h-3.7l-0.5,2.7
		c-1,0.2-2,0.6-2.8,1.2l-2.3-1.6l-2.6,2.6l1.6,2.2c-0.5,0.9-0.9,1.8-1.2,2.8l-2.7,0.5v3.7l2.7,0.5c0.2,1,0.6,2,1.2,2.8l-1.6,2.2
		l2.6,2.6l2.3-1.6c0.9,0.5,1.8,0.9,2.8,1.2l0.5,2.7h3.7l0.5-2.7c1-0.2,2-0.6,2.8-1.2l2.2,1.6l2.6-2.6l-1.6-2.2
		c0.5-0.9,0.9-1.8,1.2-2.8L48,37.6z M35.8,42.8c-3.9,0-7.1-3.2-7.1-7.1c0-3.9,3.2-7,7.1-7c3.9,0,7,3.2,7,7
		C42.8,39.6,39.6,42.8,35.8,42.8z"
            />
            <path
              d="M24.1,4c0.2-0.2,0.2-0.5,0.2-0.7c0,0,0,0,0,0c5.1,1.9,9,6.3,10.3,11.7c0,0-0.1,0-0.2,0.1l-0.2,0.2
		c-0.2,0.1-0.4,0.2-0.6,0.1l-1.4-0.5c-0.1,0-0.3-0.2-0.3-0.3L31.6,14c-0.1-0.2-0.3-0.3-0.5-0.3h-1.9c-0.1,0-0.3,0-0.4,0.1l-4.4,3.5
		c-0.1,0.1-0.2,0.3-0.2,0.5v3.3c0,0.2,0.1,0.3,0.2,0.4l1.4,1.4c0.1,0.1,0.2,0.2,0.4,0.2l2,0.2h0.1l1.4,0.1c0.1,0,0.2,0,0.3,0.1
		l1.1,0.7c0.3,0.2,0.4,0.5,0.2,0.7c0.1-0.1,0.3-0.1,0.4-0.2l0.5-3.2h2.5h0.7h1.6c0.1-0.9,0.2-1.9,0.2-2.9C37.3,8.4,28.9,0,18.6,0
		C8.4,0,0,8.4,0,18.6c0,10.3,8.4,18.6,18.6,18.6c1,0,1.9-0.1,2.9-0.2v-2.3c-0.9,0.2-1.9,0.3-2.9,0.3c-1.7,0-3.3-0.3-4.8-0.7
		c0,0,0,0,0,0l3.3-3.5c0.1-0.1,0.1-0.2,0.2-0.3l0.4-2c0-0.1,0.1-0.3,0.2-0.4l0.3-0.3c0.3-0.3,0.3-0.8-0.1-1L15.6,25
		c-0.1-0.1-0.3-0.1-0.4-0.1h-0.6c-0.3,0-0.6-0.2-0.7-0.6l-0.1-0.7c-0.1-0.3-0.3-0.6-0.7-0.6h-0.8c-0.4,0-0.8-0.4-0.7-0.8l0-0.1
		c0.1-0.4-0.2-0.8-0.6-0.8L9,21.1c-0.1,0-0.2,0-0.3-0.1l-0.6-0.3c-0.2-0.1-0.4-0.1-0.6,0l-1.1,0.4c0,0-0.1,0-0.1,0l-1.2,0.2
		c-0.5,0.1-0.9-0.4-0.8-0.8l0.1-0.3c0.1-0.4-0.2-0.8-0.7-0.8c-0.4,0-0.7-0.3-0.7-0.7v0c0-0.5-0.4-0.8-0.9-0.6l0,0
		c0-0.9,0.1-1.7,0.3-2.6c0,0,0,0,0,0c0,0,0.1,0,0.1,0l1,0c0.2,0,0.4,0.1,0.5,0.3l0.4,0.6c0.1,0.2,0.3,0.3,0.5,0.3H6l-0.5-1.3
		c-0.1-0.3,0-0.5,0.2-0.7l1.3-1.2c0.1-0.1,0.1-0.2,0.2-0.3l0.3-0.9c0.1-0.3,0.3-0.5,0.6-0.5c0.4,0,0.7-0.3,0.7-0.7v-0.3
		c0-0.2,0.1-0.5,0.3-0.6l3.4-1.8c0.3-0.2,0.5-0.6,0.2-1l-2.1-3c0,0,0,0,0,0c1.3-0.7,2.6-1.3,4.1-1.7c0,0.1,0,0.3,0,0.5
		c0.1,0.5,0.6,0.8,1.1,0.8H16c0.4,0,0.8,0.2,1,0.6l0.5,0.9c0.2,0.4,0.6,0.6,1,0.6h1.3c0.2,0,0.5-0.1,0.7-0.2l1-0.7
		C21.6,5.1,21.9,5,22.2,5l0.4,0.1c0.4,0.1,0.8-0.1,1-0.4L24.1,4z"
            />
          </g>
        </React.Fragment>
      );
    case "WIFI":
      return (
        <React.Fragment>
          <g>
            <path
              d="M48,22h-2.1C45,11.5,36.5,3,26,2.1V0h-4v2.1C11.5,3,3,11.5,2.1,22H0v4h2.1C3,36.5,11.5,45,22,45.9V48h4v-2.1
		c10.5-1,18.9-9.4,19.9-19.9H48V22z M26,41.9v-3.4h-4v3.4C13.7,41,7,34.3,6.1,26h3.4v-4H6.1C7,13.7,13.7,7,22,6.1v3.4h4V6.1
		C34.3,7,41,13.7,41.9,22h-3.4v4h3.4C41,34.3,34.3,41,26,41.9z"
            />
            <path d="M24,26c-3.1,0-5.8,2-6.8,4.9c-0.4,1.1,0.5,2.3,1.7,2.3h10.2c1.2,0,2.1-1.2,1.7-2.3C29.8,28,27.1,26,24,26z" />
            <circle cx="24" cy="21.2" r="3.3" />
            <path
              d="M31.1,21.5c-1,0-2,0.3-2.9,0.8c-0.3,1.3-1.2,2.3-2.4,2.9c1.5,0.4,2.8,1.1,3.9,2.2h5.5c1,0,1.7-0.9,1.4-1.9
		C35.9,23.2,33.7,21.5,31.1,21.5z"
            />
            <circle cx="31.1" cy="17.6" r="2.7" />
            <path
              d="M22.1,25.2c-1.2-0.6-2-1.6-2.4-2.9c-0.8-0.5-1.8-0.8-2.9-0.8c-2.6,0-4.7,1.7-5.5,4c-0.3,0.9,0.4,1.9,1.4,1.9h5.5
		C19.3,26.3,20.6,25.5,22.1,25.2z"
            />
            <circle cx="16.9" cy="17.6" r="2.7" />
          </g>
        </React.Fragment>
      );

    case "PLAY":
      return (
        <React.Fragment>
          <g>
            <g>
              <g>
                <path
                  d="M256,0C114.8,0,0,114.8,0,256s114.8,256,256,256s256-114.8,256-256S397.2,0,256,0z M256,490.7
				C126.6,490.7,21.3,385.4,21.3,256S126.6,21.3,256,21.3S490.7,126.6,490.7,256S385.4,490.7,256,490.7z"
                />
                <path
                  d="M357.8,247l-149.3-96c-3.3-2.1-7.5-2.3-10.9-0.4c-3.4,1.9-5.6,5.5-5.6,9.4v192c0,3.9,2.1,7.5,5.6,9.4
				c1.6,0.9,3.4,1.3,5.1,1.3c2,0,4-0.6,5.8-1.7l149.3-96c3-2,4.9-5.3,4.9-9S360.8,249,357.8,247z M213.3,332.5V179.5L332.3,256
				L213.3,332.5z"
                />
              </g>
            </g>
          </g>
        </React.Fragment>
      );
    default:
      return <path />;
  }
};

const SVGIcon = ({
  name = "",
  style = {},
  fill = "#000",
  viewBox = "",
  width = "100%",
  className = "",
  height = "100%",
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox || getViewBox(name)}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {getPath(name, { fill })}
  </svg>
);

export default SVGIcon;
