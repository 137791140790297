import React from 'react'
import Consulta_API from '../../services/Consulta_API';



export default function CursosList() {
   
    return (
        <div>
            <Consulta_API/>
        </div>
        
    )
}
